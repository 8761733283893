import { LineChart } from '@mantine/charts';

import { Card } from 'components/common/Card';
import {
  AdamInsights,
} from 'components/dashboard';

import RevenueLineChart from './RevenueLineChart';
import TopSellingProductBarChart from './TopSellingProductBarChart';
import TopTierCustomerBarChart from './TopTierCustomerBarChart';
import CustomerChurnRate from './CustomerChurnRate';

const Analytics = () => (
  <>
    <div className="mb-3 mt-6 flex w-full items-center space-x-2">
      <p className="text-sm font-semibold text-gray-800">Analytics</p>
      {/* <div className="flex-1 h-0.5 bg-gray-300" /> */}
    </div>

    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-8 row-start-1">
        <RevenueLineChart />
      </div>

      <div className="col-span-4 row-start-1">
        <CustomerChurnRate />
      </div>

      <div className="col-span-6 row-start-2">
        <TopSellingProductBarChart />
      </div>

      <div className="col-span-6 row-start-2">
        <TopTierCustomerBarChart />
      </div>

      <Card className="col-span-8 row-start-4 hidden">
        <Card.Body>
          <h3 className="card-title mb-4 flex flex-col items-start">
            <span className="text-sm font-semibold text-gray-800">
              Processing time per order
            </span>
            <span className="mt-1 text-xs text-gray-500">
              Average time to process an order and number of orders processed
            </span>
          </h3>
          <LineChart
            h={200}
            data={[
              {
                date: 'Jan',
                processTime: 15,
                orderAmount: 2100,
              },
              {
                date: 'Feb',
                processTime: 13.5,
                orderAmount: 2500,
              },
              {
                date: 'Mar',
                processTime: 12,
                orderAmount: 2300,
              },
              {
                date: 'Apr',
                processTime: 11,
                orderAmount: 3500,
              },
              {
                date: 'May',
                processTime: 9,
                orderAmount: 2400,
              },
              {
                date: 'June',
                processTime: 8.9,
                orderAmount: 3400,
              },
              {
                date: 'July',
                processTime: 7,
                orderAmount: 4600,
              },
              {
                date: 'Aug',
                processTime: 5,
                orderAmount: 4700,
              },
            ]}
            dataKey="date"
            series={[
              { name: 'processTime', color: 'indigo.5', label: 'Processing time (min)' },
              {
                name: 'orderAmount', color: 'indigo.3', label: 'Orders', yAxisId: 'right',
              },
            ]}
            yAxisProps={{
              domain: [5, 15],
            }}
            rightYAxisProps={{
              domain: [2000, 5000],
              ticks: [2000, 3000, 4000, 5000],
              tickFormatter(value) {
                if (value > 1000) {
                  return `${value / 1000}k`;
                }
                return value;
              },
            }}
            withLegend
            withRightYAxis
          />
        </Card.Body>
      </Card>

      <div className="hidden">
        <AdamInsights />
      </div>
    </div>
  </>
);

export default Analytics;
