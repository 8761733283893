import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Rectangle,
} from 'recharts';
import { twMerge } from 'tailwind-merge';

import { Tooltip as TooltipComponent } from 'components/ui';
import { Formatter, NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import {
  Card,
  CardHeader,
  CardTitle,
  CardContent,
  CardDescription,
} from './ChartCard';

type Data = {
  [key: string]: any;
};

type SelectOption = {
  value: any;
  label: string;
};

interface Props {
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;
  data: Data[];
  xDataKey: string;
  yDataKey: string;

  chartDivClassName?: string;
  selectOptions?: SelectOption[];
  selectedOptionValue?: any;
  setOption?: (value: any) => void;
  horizontal?: boolean;

  tooltipFormatter?: Formatter<ValueType, NameType>;
}

const EllipsisTick = ({
  x,
  y,
  payload,
}: {
  x: number;
  y: number;
  payload: { value: string };
}) => {
  const text = payload.value;
  const truncatedText = text.length > 10 ? `${text.slice(0, 10)}...` : text;
  return (
    <g transform={`translate(${x},${y})`}>
      <TooltipComponent label={payload.value}>
        <text x={0} y={0} dy={4} textAnchor="end" fill="#6B7280" width={100}>
          {truncatedText}
        </text>
      </TooltipComponent>
    </g>
  );
};

const BarChartComponent = ({
  title,
  subtitle,
  data,
  xDataKey,
  yDataKey,
  horizontal,
  chartDivClassName,
  selectOptions,
  selectedOptionValue,
  setOption,
  tooltipFormatter = (value) => [value.toLocaleString()],
}: Props) => (
  <Card className="w-full">
    <CardHeader>
      <div className="flex items-center justify-between">
        <div className="flex flex-col space-y-1">
          <CardTitle>{title}</CardTitle>
          <CardDescription>{subtitle}</CardDescription>
        </div>

        {selectOptions && setOption && (
          <div className="inline-flex items-center rounded-md bg-whiter p-1.5">
            {selectOptions.map((option) => (
              <button
                type="button"
                className={twMerge(
                  'shadow-card hover:shadow-card rounded px-slg py-1 text-xs font-medium text-black hover:bg-white',
                  selectedOptionValue === option.value && 'bg-white',
                )}
                onClick={() => setOption(option.value)}
              >
                {option.label}
              </button>
            ))}
          </div>
        )}
      </div>
    </CardHeader>
    <CardContent>
      <div className={twMerge(
        'h-96 w-full',
        chartDivClassName,
        data.length === 0 && 'hidden',
      )}
      >
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            layout={horizontal ? 'horizontal' : 'vertical'}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              className="stroke-gray-200"
              horizontal={false}
            />
            <XAxis
              type="number"
              tick={horizontal ? EllipsisTick : { fill: '#6B7280' }}
              tickLine={{ stroke: '#6B7280' }}
              tickFormatter={(value) => value.toLocaleString()}
            />
            <YAxis
              type="category"
              dataKey={xDataKey}
              tick={horizontal ? { fill: '#6B7280' } : EllipsisTick}
              tickLine={{ stroke: '#6B7280' }}
              width={140}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#fff',
                border: '1px solid #e5e7eb',
                borderRadius: '0.5rem',
              }}
              formatter={tooltipFormatter}
              cursor={{ fill: 'transparent' }}
            />
            <Bar
              dataKey={yDataKey}
              fill="#3B82F6"
              radius={[0, 4, 4, 0]}
              maxBarSize={30}
              activeBar={<Rectangle fill="#5a3bf6" />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {
        data.length === 0 && (
          <div className="pt-10 w-full flex justify-center">
            <div className="text-sm text-gray-500">No data available</div>
          </div>
        )
      }
    </CardContent>
  </Card>
);

export default BarChartComponent;
