import { NavLink, NavigateFunction } from 'react-router-dom';
import { HoverCard, Tooltip } from '@mantine/core';
import {
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  BuildingStorefrontIcon,
  PlusIcon,
  BeakerIcon,
  TagIcon,
  ShoppingCartIcon,
  InboxStackIcon,
  CommandLineIcon,
} from '@heroicons/react/24/outline';

import { twMerge } from 'tailwind-merge';
import { globalOrderDrafts } from 'state/globalOrderDrafts';
import { observer } from 'mobx-react-lite';
import { globalThreads } from 'state/globalThreads';
import { Button } from '../../../ui/Button';
import { ROUTES } from '../../../../config/routes';

type SidebarItem = {
  hoverTitle: string;
  path?: string;
  basePath?: string; // special case for inbox, which has a dynamic path
  icon?: React.ElementType;
  dropdownItems?: SidebarItem[];
  itemsBadgeCount?: string;
};

interface Props {
  navigate: NavigateFunction;
  pathname: string;
}

const Sidebar = observer(({ navigate, pathname }: Props) => {
  globalOrderDrafts.loadOrderDraftsCount(true);
  globalThreads.load(true);
  const orderDraftsCount = globalOrderDrafts.count > 99 ? '99+' : globalOrderDrafts.count?.toString();
  const unreadThreadsCount = globalThreads.unreadCount > 99 ? '99+' : globalThreads.unreadCount?.toString();

  const sidebarItems: SidebarItem[] = [
    {
      hoverTitle: 'Home',
      path: ROUTES.HOME,
      icon: HomeIcon,
    },
    // DEV-702: TODO(ntauth): Re-enable once v2 chat endpoints are implemented
    // {
    //   hoverTitle: 'Chats',
    //   path: ROUTES.CHATS,
    //   icon: ChatBubbleLeftRightIcon,
    // },
    {
      hoverTitle: 'Inbox',
      path: ROUTES.INBOX({}),
      basePath: '/inbox',
      icon: InboxStackIcon,
      itemsBadgeCount: unreadThreadsCount,
    },
    {
      hoverTitle: 'Orders',
      path: ROUTES.ORDERS,
      icon: ShoppingCartIcon,
      itemsBadgeCount: orderDraftsCount,
    },
    {
      hoverTitle: 'Products',
      path: ROUTES.PRODUCTS,
      icon: TagIcon,
    },
    {
      hoverTitle: 'Customers',
      path: ROUTES.CUSTOMERS,
      icon: BuildingStorefrontIcon,
    },
    {
      hoverTitle: 'Contacts',
      path: ROUTES.SUBJECTS,
      icon: UsersIcon,
    },
    {
      hoverTitle: 'Team members',
      path: ROUTES.TEAM_MEMBERS,
      icon: UserGroupIcon,
    },
    {
      hoverTitle: 'Instructions',
      path: ROUTES.INSTRUCTIONS,
      icon: BeakerIcon,
    },
    {
      hoverTitle: 'Audit logs',
      icon: CommandLineIcon,
      dropdownItems: [
        {
          hoverTitle: 'ERP synchronization logs',
          path: ROUTES.AUDIT_ERP_SYNC_LOGS,
        },
      ],
    },
  ];

  return (
    <div
      style={{ minWidth: 60, maxWidth: 60 }}
      className="hidden-scrollbar relative flex h-full flex-col overflow-y-auto border-r-[1px] border-solid p-smd shadow-md"
    >
      <nav className="flex flex-1 flex-col items-center gap-smd pt-1">
        <HoverCard
          shadow="md"
          position="right-start"
          openDelay={100}
          transitionProps={{ transition: 'scale-x', duration: 400 }}
        >
          <HoverCard.Target>
            <div className="group flex w-full cursor-pointer flex-row items-center justify-center gap-smd rounded-sm bg-primary-500 p-smd text-sm text-white hover:bg-primary-600">
              <PlusIcon
                height={22}
                width={22}
                className="text-white transition duration-500 ease-in-out group-hover:rotate-[180deg]"
              />
            </div>
          </HoverCard.Target>
          <HoverCard.Dropdown p={0}>
            <div className="absolute -left-1 top-3 h-3 w-3 rotate-[45deg] transform bg-white border-b border-l border-[#E8E8E8]" />
            <Button
              title="New order draft"
              theme="secondary"
              radius="sm"
              className="w-full text-center font-semibold hover:bg-light-gray-50"
              onClick={() => {
                navigate(ROUTES.PROCESS_NEW_ORDER_DRAFTS);
              }}
            />
            <Button
              title="Process order drafts"
              theme="secondary"
              radius="sm"
              className="w-full text-center font-semibold hover:bg-light-gray-50"
              onClick={() => {
                navigate(ROUTES.PROCESS_ORDER_DRAFTS);
              }}
            />
          </HoverCard.Dropdown>
        </HoverCard>

        {sidebarItems.map((sidebarItem) => {
          if (sidebarItem.dropdownItems?.length > 0) {
            return (
              <HoverCard
                key={`sidebar-dropdown-${sidebarItem.hoverTitle}`}
                shadow="md"
                position="right-start"
                openDelay={100}
                transitionProps={{ transition: 'scale-x', duration: 400 }}
              >
                <HoverCard.Target>
                  <div className="group flex w-full cursor-pointer flex-row items-center justify-center gap-smd rounded-sm p-smd text-sm bg-white">
                    {sidebarItem.icon && (
                    <sidebarItem.icon
                      height={22}
                      width={22}
                      className="transition duration-500 ease-in-out text-[rgb(87,83,78)]"
                    />
                    )}
                  </div>
                </HoverCard.Target>
                <HoverCard.Dropdown p={0} className="relative">
                  <div className="absolute -left-1 top-3 h-3 w-3 rotate-[45deg] transform bg-white border-b border-l border-[#E8E8E8]" />
                  {sidebarItem.dropdownItems.map((dropdownItem) => (
                    <Button
                      title={dropdownItem.hoverTitle}
                      theme="secondary"
                      radius="sm"
                      className="w-full text-center font-semibold hover:bg-light-gray-50"
                      icon={dropdownItem.icon && <dropdownItem.icon height={18} width={18} className="gap-smd text-[rgb(87,83,78)]" />}
                      onClick={() => {
                        navigate(dropdownItem.path);
                      }}
                      key={`sidebar-dropdown-${dropdownItem.path}-${dropdownItem.hoverTitle}`}
                    />
                  ))}
                </HoverCard.Dropdown>
              </HoverCard>
            );
          }

          return (
            <Tooltip
              label={sidebarItem.hoverTitle}
              transitionProps={{ transition: 'scale-x', duration: 200 }}
              bg="white"
              styles={{
                tooltip: {
                  color: 'black',
                  border: '1.5px solid #E8E8E8',
                  boxShadow: '0 1px 2px 0 rgb(0 0 0 / 0.05)',
                },
              }}
              position="right"
              key={`sidebar-${sidebarItem.path}-${sidebarItem.hoverTitle}`}
            >
              <NavLink
                to={sidebarItem.path}
            // className={drawerButtonStyle(sidebarItem.path)}
                className={twMerge(
                  'flex w-full flex-row items-center justify-center gap-smd rounded-sm p-smd text-sm text-gray-600',
                  (sidebarItem.path === '/' && pathname === '/')
                || (sidebarItem.path !== '/'
                  && (pathname.startsWith(sidebarItem.path) || pathname.startsWith(sidebarItem.basePath)))
                    ? 'bg-[#f0f0f0]'
                    : 'hover:bg-[#f0f0f0]',
                )}
              >
                <div className="relative">
                  <sidebarItem.icon
                    height={22}
                    width={22}
                    className="text-[rgb(87,83,78)]"
                  />
                  {sidebarItem.itemsBadgeCount && (
                  <span className="absolute -right-2/3 -top-2 flex min-w-[18px] items-center justify-center rounded-full bg-danger-500 px-1 text-xs text-white transition-transform duration-200">
                    {sidebarItem.itemsBadgeCount}
                  </span>
                  )}
                </div>
              </NavLink>
            </Tooltip>
          );
        })}
      </nav>
    </div>
  );
});

export default Sidebar;
