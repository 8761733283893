import { MessageIntent, MessageChannel } from '../models/Message';
import { OrderStatus } from '../features/order/models/Order';
import { UserRole } from '../models/User';
import { TagVariant } from '../types/tag';

const formatOrderStatus = (status?: OrderStatus) => {
  switch (status) {
    case OrderStatus.New:
      return 'created';
    case OrderStatus.Confirmed:
      return 'confirmed';
    case OrderStatus.Rejected:
      return 'rejected';
    case OrderStatus.TimedOut:
      return 'timed out';
    default:
      return 'being processed';
  }
};

const getTagVariant = (tagTitle: string) => {
  switch (tagTitle) {
    case 'Confirmed':
      return TagVariant.GREEN;
    case 'Rejected':
    case 'Timed out':
      return TagVariant.RED;
    default:
      return TagVariant.BLUE;
  }
};

// TODO: delete
const spanClassName = (tagTitle: string) => {
  switch (tagTitle) {
    case 'confirmed':
      return 'inline-flex items-center gap-x-1.5 rounded-sm bg-success-100 px-2 py-1 text-xs font-medium text-success-700';
    case 'rejected':
      return 'inline-flex items-center gap-x-1.5 rounded-sm bg-danger-100 px-2 py-1 text-xs font-medium text-danger-700';
    case 'timed out':
      return 'inline-flex items-center gap-x-1.5 rounded-sm bg-danger-100 px-2 py-1 text-xs font-medium text-danger-700';
    default:
      return 'inline-flex items-center gap-x-1.5 rounded-sm bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700';
  }
};

// TODO: Delete
const fillColour = (tagTitle: string) => {
  switch (tagTitle) {
    case 'confirmed':
      return 'fill-success-500';
    case 'rejected':
      return 'fill-danger-500';
    case 'timed out':
      return 'fill-danger-500';
    default:
      return 'fill-blue-500';
  }
};

const getTagTitle_temp = (status?: OrderStatus, isAssigned?: boolean) => {
  switch (status) {
    case OrderStatus.Confirmed:
      return 'Confirmed';
    case OrderStatus.Rejected:
      return 'Rejected';
    case OrderStatus.TimedOut:
      return 'Timed out';
    default:
      if (isAssigned) return 'Processing...';
      return 'To be processed';
  }
};

const getTagTitle = (tagTitle: string) => {
  switch (tagTitle) {
    case 'confirmed':
      return 'Confirmed';
    case 'rejected':
      return 'Rejected';
    case 'timed out':
      return 'Timed out';
    default:
      return 'To be processed';
  }
};

const getFuncName = (tagTitle: string) => {
  switch (tagTitle) {
    case 'confirmed':
      return 'See order details';
    case 'rejected':
      return 'See order details';
    case 'timed out':
      return 'See order details';
    default:
      return 'Process order';
  }
};

const getOrderStatusColor = (status?: OrderStatus) => {
  switch (status) {
    case OrderStatus.New:
      return 'lightblue';
    case OrderStatus.Confirmed:
      return 'lightgreen';
    case OrderStatus.Rejected:
      return 'red';
    case OrderStatus.TimedOut:
      return 'red';
    default:
      return 'orange';
  }
};

const formatUserRole = (role: UserRole) => {
  switch (role) {
    case UserRole.AiAssistant:
      return 'AI Assistant';
    case UserRole.RestaurantAdmin:
      return 'Restaurant admin';
    case UserRole.RestaurantUser:
      return 'Restaurant user';
    case UserRole.SupplierAdmin:
      return 'Supplier admin';
    case UserRole.SupplierUser:
      return 'Supplier user';
    default:
      return '';
  }
};

const userRoleToTagVariant = (role: UserRole) => {
  switch (role) {
    case UserRole.AiAssistant:
      return TagVariant.GREEN;
    case UserRole.RestaurantAdmin:
    case UserRole.SupplierAdmin:
      return TagVariant.RED;
    case UserRole.RestaurantUser:
    case UserRole.SupplierUser:
      return TagVariant.BLUE;
    default:
      return TagVariant.BLUE;
  }
};

const formatMessageSource = (source: MessageChannel) => {
  switch (source) {
    case MessageChannel.IMAP:
    case MessageChannel.SMTP:
      return 'Email';
    case MessageChannel.VOICE_BRIDGE:
      return 'Voice';
    case MessageChannel.INTERNAL:
    default:
      return 'Internal';
  }
};

const intentToTagVariant = (intent: MessageIntent): TagVariant => {
  switch (intent) {
    case MessageIntent.ORDER:
      return TagVariant.BLUE;
    case MessageIntent.OTHER:
    default:
      return TagVariant.GREEN;
  }
};

// Sorts intents lexicographically, while ensuring that intents in the
// sticky list are always at the front in the specified order.
const sortIntents = (intents: MessageIntent[]) => {
  const stickyIntents = intents
    .filter((intent) => intent === MessageIntent.ORDER)
    .sort();
  const nonStickyIntents = intents
    .filter((intent) => intent !== MessageIntent.ORDER)
    .sort();
  return [...stickyIntents, ...nonStickyIntents];
};

export {
  formatOrderStatus,
  getOrderStatusColor,
  formatUserRole,
  userRoleToTagVariant,
  formatMessageSource,
  getTagVariant,
  getTagTitle_temp,
  spanClassName,
  fillColour,
  getTagTitle,
  getFuncName,

  intentToTagVariant,
  sortIntents,
};
