import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer,
} from 'recharts';
import { twMerge } from 'tailwind-merge';
import { Formatter, NameType, ValueType } from 'recharts/types/component/DefaultTooltipContent';
import { BaseAxisProps } from 'recharts/types/util/types';
import {
  Card, CardHeader, CardTitle, CardContent,
  CardDescription,
} from './ChartCard';

type Data = {
  [key: string]: any;
};

type SelectOption = {
  value: any;
  label: string;
};

interface Props {
  data: Data[];
  xDataKey: string;
  yDataKey: string;
  title: string | React.ReactNode;
  subtitle: string | React.ReactNode;

  chartDivClassName?: string;
  selectOptions?: SelectOption[];
  selectedOptionValue?: any;
  setOption?: (value: any) => void;

  tickFormatter?: BaseAxisProps['tickFormatter'];
  toolTipFormatter?: Formatter<ValueType, NameType>;
}

const LineChartComponent = ({
  data, xDataKey, yDataKey, title, subtitle, chartDivClassName, selectOptions, selectedOptionValue, setOption,
  tickFormatter = (value) => value.toLocaleString(),
  toolTipFormatter = (value) => [value.toLocaleString()],
}: Props) => (
  <Card className="w-full">
    <CardHeader>
      <div className="flex justify-between items-center">
        <div className="flex flex-col space-y-1">
          <CardTitle className="flex items-center w-fit">
            {title}
          </CardTitle>
          <CardDescription>
            {subtitle}
          </CardDescription>
        </div>

        {
            selectOptions && setOption && (
            <div className="inline-flex items-center rounded-md bg-whiter p-1.5">
                {
                    selectOptions.map((option) => (
                      <button
                        type="button"
                        className={twMerge(
                          'shadow-card hover:shadow-card rounded px-slg py-1 text-xs font-medium text-black hover:bg-white',
                          selectedOptionValue === option.value && 'bg-white',
                        )}
                        onClick={() => setOption(option.value)}
                      >
                        {option.label}
                      </button>
                    ))
                }
            </div>
            )
        }
      </div>
    </CardHeader>
    <CardContent>
      <div className={twMerge(
        'h-96 w-full',
        chartDivClassName,
        data.length === 0 && 'hidden',
      )}
      >
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" className="stroke-gray-200" />
            <XAxis
              dataKey={xDataKey}
              tick={{ fill: '#6B7280' }}
              tickLine={{ stroke: '#6B7280' }}
              interval={Math.round((data?.length ?? 0) / 10)}
            />
            <YAxis
              tick={{ fill: '#6B7280' }}
              tickLine={{ stroke: '#6B7280' }}
              tickFormatter={tickFormatter}
            />
            <Tooltip
              contentStyle={{
                backgroundColor: '#fff',
                border: '1px solid #e5e7eb',
                borderRadius: '0.5rem',
              }}
              formatter={toolTipFormatter}
            />
            <Line
              type="monotone"
              dataKey={yDataKey}
              stroke="#2563EB"
              strokeWidth={2}
              dot={{ fill: '#2563EB', strokeWidth: 2 }}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>

      {
          data.length === 0 && (
            <div className="pt-10 w-full flex justify-center">
              <div className="text-sm text-gray-500">No data available</div>
            </div>
          )
        }
    </CardContent>
  </Card>
);
export default LineChartComponent;
